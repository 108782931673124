import React,{useState,useEffect} from "react";
import "bootstrap/dist/css/bootstrap.css";
import Home from './Pages/Home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux';
import Login from "./Pages/Auth/Login";
import DashboardHome from './Dashboard Pages/Home/Home'
import NotFound from "./Pages/404/NotFound";
import About from "./Pages/About/About";
import Engagements from "./Pages/Engagements/Engagements";
import spinner from './assets/Icons/Loader.gif'
import ReactGA from "react-ga4";
import LoginMobile from "./Pages/Auth/MobComponents/LoginMobile";
import RegisterMobile from "./Pages/Auth/MobComponents/RegisterMobile";
import OtpMob from "./Pages/Auth/MobComponents/OtpMob";
import PasswordMob from "./Pages/Auth/MobComponents/PasswordMob";
import Science from "./Pages/Science/Science";
import ForgetPassword from "./Pages/Auth/MobComponents/ForgetPassword";
import ForgetPasswordOtp from "./Pages/Auth/MobComponents/ForgetPasswordOtp";
import ForgetPasswordUpdate from "./Pages/Auth/MobComponents/ForgetPasswordUpdate";
import NeedDiscovery from "./Pages/NeedDiscovery/NeedDiscovery";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import challengeIcon from './assets/Icons/challenge_icon.png'
import './App.css'
function App() {
  ReactGA.initialize("G-M29RW0BXQH");
  ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "nCount" });
  const [loading,setLoading] = useState(true);
  useEffect(()=>{
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 100);

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(timeout);
  },[])
  console.log(loading,"loading-----------------")

  const user = useSelector(state=>state.userInfo);
  const appInfo = useSelector(state=>state.appInfo);
  const registerUserInfo = useSelector(state=>state.registerUserInfo);
  console.log(user,registerUserInfo)
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(false);
    }, 10000); // 10 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);
  return (
    
      <BrowserRouter>
       <div >
      <Popup open={isOpen} closeOnDocumentClick={false}>
        <div >
          <center>
            <h4 style={{color:'white'}}>Version Update!!</h4>
            <img src={challengeIcon}  style={{marginTop:10,width:'20%'}}/>
          <p style={{color:'white',marginTop:5}}>The new version has launched. Please visit <a style={{color:'white',marginTop:5}} href="https://ncount.in/" target="_blank">here</a>.</p>
            </center>
        </div>
      </Popup>
    </div>
      <Routes>
           <Route path="/" element={<Home />} />
          <Route path="/auth" element={user.authToken?<DashboardHome/>:<Login/>} />
          <Route path="/Dashboard" element={user.authToken?<DashboardHome/>:<LoginMobile/>}/>
          <Route path="/about" element={<About/>} />
          <Route path = '/need-discovery' element={<NeedDiscovery/>}/>
          <Route path="/engagements" element={<Engagements/>} />
           <Route path="/mobile-auth" element={<LoginMobile/>}/>
           <Route path="/mobile-register" element={<RegisterMobile/>}/>
           <Route path="/mobile-otp" element={registerUserInfo.phoneNumber?<OtpMob/>:<RegisterMobile/>}/>
           <Route path="/mobile-password" element={registerUserInfo.phoneNumber?<PasswordMob/>:<RegisterMobile/>}/>
 
           <Route path="/mobile-forget-password" element={<ForgetPassword/>}/>
           <Route path="/mobile-forget-password-otp" element={<ForgetPasswordOtp/>}/>
           <Route path="/mobile-forget-password-update" element={<ForgetPasswordUpdate/>}/>
          <Route path="/science" element={<Science/>} />
 
          <Route path="" element={NotFound} /> 
          <Route path="*" element={<NotFound />} />
        <Route element={<NotFound />} />
      </Routes>
    </BrowserRouter>

  );
} 
export default App;
